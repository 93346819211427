// import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Button, Card, Image, Col, Form, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { logoutUser } from "../../redux/actions";
import AuthModal from "../../components/Modal";
import { Alert } from "react-bootstrap";
import api from "../../utils/ApiMethod";
import ViewModal from "./PaitentsViewModal";

import img from "../../assets/images/new.gif";

interface OrgtnalSruItem {
  _id?: string;
  aboutText?: string;
  isDeleted?: boolean;
}

interface userData {
  name?: string;
  email?: string;
  password?: string;
  hobbies?: string;
  role?: string;
  isDeleted?: boolean;
  imageUrl?: string;
  _id?: string;
  department?: string;
  designation?: string;
  position?: number;
  doj?: string;
  title: string;
  inventors: string;
  applicantName: string;
  applicationNumber: string;
  patentNumber: string;
  country: string;
  subjectCategory: string;
  fillingDate: string;
  publicationDate: string;
  image: string;
  status: string;

}

interface OrgtnalStru {
  _id: string;
  name?: string;
  bannerUrl?: string;
  mobileBannerUrl?: string;
  aboutText?: string;
  // OrgtnalSruItem:array;
  isDeleted: boolean;
  email?: string;
  hobbies?: string;
  role?: string;
  superDuperAdminApprovalStatus?: string;
  superAdminApprovalStatus?: string;
  adminApprovalStatus?: string;
  innerData?: userData[] | [];
  department?: string;
  designation?: string;
  doj?: string;
  title: string;
  inventors: string;
  applicantName: string;
  applicationNumber: string;
  patentNumber: string;
  country: string;
  subjectCategory: string;
  fillingDate: string;
  publicationDate: string;
  image: string;
  status: string;

}

interface UpdateServiceSectionProps {
  itemId: string; // Replace 'boolean' with the appropriate type if needed
  parentId?: string;
  innerdata: userData[];
}
interface ParentId {
  itemId?: string; // Replace 'boolean' with the appropriate type if needed
  id?: string;
  parentId?: string;
}
interface id {
  id?: string;
}

// -------------------------------------  for organizational Structured Item  ---------------
// ---999999999999999999 for update item
const UpdatePatent: React.FC<UpdateServiceSectionProps> = ({
  itemId,
  parentId,
  innerdata,
}) => {
  const [validated, setValidated] = useState<boolean>(false);
  const [serviceSection, setServiceSection] = useState<OrgtnalStru[]>([]);
  const [aboutText, setAboutText] = useState("");
  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");
  const [role, setRole] = useState("");
  const [description, setDescription] = useState("");
  const [isError, setIsError] = useState<boolean>(false);
  const [position, setPosition] = useState<string>("0");
  const [doj, setDoj] = useState("");


  const [title, setTitle] = useState<string>("");
  const [inventors, setInventors] = useState<string>(""); // or array if there are multiple inventors
  const [applicantName, setApplicantName] = useState<string>("");
  const [applicationNumber, setApplicationNumber] = useState<string>("");
  const [patentNumber, setPatentNumber] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [subjectCategory, setSubjectCategory] = useState<string>("");
  const [fillingDate, setFillingDate] = useState<string>(""); // Assuming date is in string format (ISO string)
  const [publicationDate, setPublicationDate] = useState<string>(""); // Same as above
  const [status, setStatus] = useState<string>("");





  const [designation, setDesignation] = useState("");
  const [isResponse, setIsResponse] = useState("");
  const [url, setUrl] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  // console.log("You are in the update section");
  // ------------------------- for saving the data to updte
  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
      ? user.user.moniterd_ttl
      : "";
  let profileName = user.name ? user.name : user.user.name;

  useEffect(() => {


    const fetchData = async () => {
      try {


        let admin =
          Role == "Chess"
            ? `/api/getPatent/${profileId}/${Key_Key}`
            : `/api/getPatent/${profileId}/${Key_Key}`;
        const response = await api.fetchDataAuth(
          // `/user/${profileId}/${Key_Key}/${Departmentforfilter}`
          `${admin}`
        );

        // const response = await api.fetchDataAuth(
        //   `/user/${profileId}/${Key_Key}`
        // );
        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.message;
          setIsResponse(errorMessage);
        }

        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = "https://admin.upums.ac.in/auth/login"; // Use the full URL, including the protocol (http or https)
        }
        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;
        setServiceSection(data);
      } catch (error) {
        // Handle fetch error
        console.error("Error during fetch:", error);
      }
    };
    fetchData(); // Call the async function directly
    // Note: You may want to add a cleanup function here if needed
  }, [profileId, Key_Key]); // Include dependencies if needed

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  //   ------------------------------------------ setting image in the input--------------------------------
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const UpdateOrgStruItem = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const formData = new FormData();
      formData.append("aboutText", aboutText);
      formData.append("name", name);
      formData.append("position", position);

      formData.append("role", role);
      formData.append("modifiedBy", profileName);
      formData.append("department", department);
      formData.append("designation", designation);
      formData.append("doj", doj);


      formData.append("title", title);
      formData.append("inventors", inventors);
      formData.append("applicantName", applicantName);
      formData.append("applicationNumber", applicationNumber);
      formData.append("patentNumber", patentNumber);
      formData.append("country", country);
      formData.append("subjectCategory", subjectCategory);
      formData.append("fillingDate", fillingDate);
      formData.append("publicationDate", publicationDate);
      formData.append("status", status);

      if (image) {
        formData.append("image", image);
      }
      try {
        // const response = await fetch(
        //   `https://api.upums.ac.in/api/update/user/${profileId}/editor/${itemId}/${Key_Key}`,
        //   // `https://api.upums.ac.in/api/update/user/${profileId}/editor/${itemId}`,
        //   {
        //     method: "PATCH",
        //     body: formData,
        //     credentials: "include",
        //   }
        // );
        // const response = await api.updatePatchData(
        //   `/api/update/user/${profileId}/editor/${itemId}/${Key_Key}`,
        //   formData
        // );
        const response = await api.updatePatchData(
          `/api/updatePatent/${profileId}/${itemId}/${Key_Key}`,
          formData
        );

        // console.log(response.status, "response.status");

        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = "https://admin.upums.ac.in/auth/login"; // Use the full URL, including the protocol (http or https)
        }

        if (response.status === 429) {
          // console.log("response---6566656", response)
          setShowModal(true);
        }
        // console.log("response--statue", response)

        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.json();
          setIsResponse(errorMessage.message);
        }

        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Assuming you want to parse the JSON response

        if (response.status == 200 || response.status == 201) {
          alert("Patent updated Successfully");
          window.location.reload();
        }
      } catch (error: any) {
        // setIsError(true);
        // setIsResponse(error.message)
        console.error("Error during edit the banner:", error);
      }
    }
    setValidated(true);
  };
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;

  let Departmentforfilter = user.department
    ? user.department
    : user.user.department;

  const Cancle = () => {
    window.location.reload();
  };

  return (
    <>
      <Card>
        <Card.Body>
          {(innerdata || [])
            ?.filter((item) => {
              // console.log("from the line 248--", item._ === itemId);
              return item._id === itemId;
            })
            ?.map((filterItem, index) => (
              <>
                <Form
                  style={{ width: "100%" }}
                  onSubmit={UpdateOrgStruItem}
                  encType="multipart/form-data"
                  key={index}
                >
                  <h4>Update Patent</h4>
                  {/* <> */}
                  {isError && (
                    <Alert variant="danger" className="my-2">
                      {isResponse}
                    </Alert>
                  )}
                  {showModal && <AuthModal />}
                  {/* <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>Name</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={filterItem?.name}
                      defaultValue={filterItem?.name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group> */}

                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                      <h5>Title of the Patent</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={filterItem?.title}
                      defaultValue={filterItem?.title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>

                  {/* Inventors */}
                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                      <h5>Principal Inventor</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={filterItem?.inventors}
                      defaultValue={filterItem?.inventors}
                      onChange={(e) => setInventors(e.target.value)}
                    />
                  </Form.Group>

                  {/* Applicant Name */}
                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                      <h5>Co-inventors (If any)</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={filterItem?.applicantName}
                      defaultValue={filterItem?.applicantName}
                      onChange={(e) => setApplicantName(e.target.value)}
                    />
                  </Form.Group>

                  {/* Application Number */}
                  {/* <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                      <h5>Application Number</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={filterItem?.applicationNumber}
                      defaultValue={filterItem?.applicationNumber}
                      onChange={(e) => setApplicationNumber(e.target.value)}
                    />
                  </Form.Group> */}

                  {/* Patent Number */}
                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                      <h5>Patent No./Design No.</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={filterItem?.patentNumber}
                      defaultValue={filterItem?.patentNumber}
                      onChange={(e) => setPatentNumber(e.target.value)}
                    />
                  </Form.Group>

                  {/* Country */}
                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                      <h5>Filing Country</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={filterItem?.country}
                      defaultValue={filterItem?.country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </Form.Group>

                  {/* Subject Category */}
                  {/* <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                      <h5>Subject Category</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={filterItem?.subjectCategory}
                      defaultValue={filterItem?.subjectCategory}
                      onChange={(e) => setSubjectCategory(e.target.value)}
                    />
                  </Form.Group> */}

                  {/* Filing Date */}
                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                      <h5>Filing Date</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="date"
                      placeholder={filterItem?.fillingDate}
                      defaultValue={filterItem?.fillingDate}
                      onChange={(e) => setFillingDate(e.target.value)}
                    />
                  </Form.Group>

                  {/* Publication Date */}
                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                      <h5>Publication Date</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="date"
                      placeholder={filterItem?.publicationDate}
                      defaultValue={filterItem?.publicationDate}
                      onChange={(e) => setPublicationDate(e.target.value)}
                    />
                  </Form.Group>

                  {/* Patent Status */}
                  <Form.Group className="mb-3 mt-2">
                    <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                      <h5>Patent Status</h5>
                    </Form.Label>
                    <Form.Select
                      className="accordion-item"
                      name="status"
                      value={filterItem?.status}
                      // onChange={(e) => setStatus(e.target.value)}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setStatus(e.target.value)
                      }
                    >
                      <option value="">Select Status</option>
                      <option value="Published">Published</option>
                      <option value="Granted">Granted</option>
                      <option value="Pending">Pending</option>
                      <option value="Rejected">Rejected</option>
                    </Form.Select>
                  </Form.Group>



                  <Row className="mt-2">
                    <Col lg={5}>
                      <Button type="submit">Update</Button>
                    </Col>
                  </Row>
                  <Form.Group className="pb-1"></Form.Group>
                </Form>
                <Row>
                  <Col lg={12}>
                    <button
                      onClick={() => Cancle()}
                      className="btn btn-primary"
                    >
                      Cancle
                    </button>
                  </Col>
                </Row>
              </>
            ))}
        </Card.Body>
      </Card>
    </>
  );
};
// _________________________________________________________

// const DeleteOriStruItem = ({id}) =>
const DeletePatent: React.FC<ParentId> = ({ id }) => {
  const [validated, setValidated] = useState<boolean>(false);
  const [innerdata, setInnerData] = useState<OrgtnalSruItem[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [isEditItem, setIsEditItem] = useState<string>("");
  const [isResponse, setIsResponse] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showForAprov, setShowForAprov] = useState<boolean>(false);
  const [showForAprovItemId, setShowForAprovItemId] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<OrgtnalStru[]>([]);
  console.log("Data...", data);
  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth.id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
      ? user.user.moniterd_ttl
      : "";
  let Departmentforfilter = user.department
    ? user.department
    : user.user.department;
  // --delete org str item
  const deleteItem = async (itemId: string) => {
    // console.log(user, "user.token");
    try {
      // const response = await fetch(
      //   `https://api.upums.ac.in/api/delete/user/${profileId}/employee/${itemId}/${Key_Key}`,
      //   // `https://api.upums.ac.in/api/delete/user/${profileId}/employee/${itemId}`,

      //   {
      //     method: "PATCH",
      //     // headers: {
      //     //   token: `${user?.token}`, // Add the token to the Authorization header
      //     // },
      //     credentials: "include",
      //   }
      // );
      const response = await api.updatePatchDataDelete(
        `/api/deletePatent/${profileId}/${itemId}/${Key_Key}`,
        ""
      );
      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = "https://admin.upums.ac.in/auth/login"; // Use the full URL, including the protocol (http or https)
      }

      if (!response.status == true) {
        // console.log("response--", await response.json())
        let errorMsg = await response.json();
        setIsResponse(errorMsg.message);
        // console.log("response from update user---",response)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      // Assuming you want to parse the JSON response

      if (
        response.status == 200 ||
        response.status == 201 ||
        response.status == 204
      ) {
        alert("Are you sure you want to remove this patent?");
        window.location.reload();
      }
    } catch (error) {
      // setIsResponse("Try after Some time")
      setShowModal(true);
      console.error("Error during delete the patent:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(
        //   `https://api.upums.ac.in/user/${profileId}/${Key_Key}`,
        //   {
        //     method: 'GET',
        //     credentials: 'include',
        //   }
        // );
        let admin =
          Role == "Chess"
            ? `/api/getPatent/${profileId}/${Key_Key}`
            : `/api/getPatent/${profileId}/${Key_Key}`;
        const response = await api.fetchDataAuth(
          // `/user/${profileId}/${Key_Key}/${Departmentforfilter}`
          `${admin}`
        );
        // const response = await api.fetchDataAuth(
        //   `/user/${profileId}/${Key_Key}`
        // );
        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = "https://admin.upums.ac.in/auth/login"; // Use the full URL, including the protocol (http or https)
        }
        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const res = await response.data;
        setData(res?.data);
      } catch (error) {
        // Handle fetch error
        console.error("Error during fetch:", error);
      }
    };

    fetchData(); // Call the async function directly

    // Note: You may want to add a cleanup function here if needed
  }, [profileId, Key_Key]); // Include dependencies if needed

  const editItem = async (id: string) => {
    let newEditItem = data.find((elem) => {
      return elem._id === id;
    });
    setIsEditItem(id);
  };
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;

  const ViewData = async (id: string) => {
    let newEditItem = data.find((elem) => {
      return elem._id === id;
    });
    setIsEditItem(id);
    setShowForAprov(!showForAprov);
    // setShowForAprovItemId(!showForAprovItemId)
  };

  const closeViewData = () => {
    setShowForAprov(false);
    setIsEditItem("");
  };

  // console.log(data, "data----");

  // console.log('role', Role)
  return (
    // <h1>hi</h1>
    <>
      {showForAprov == false ? (
        <div className="input-group w-50 mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            style={{
              borderRadius: ".5rem",
              border: "1px solid red",
              // borderTop: '2px solid red',
              // borderBottom: '2px solid blue',
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      ) : (
        ""
      )}

      {/*   */}
      {showForAprov ? (
        <Button className="mt-3 mb-3" onClick={() => closeViewData()}>
          Close
        </Button>
      ) : (
        ""
      )}

      {isEditItem && !showForAprov ? (
        <Row>
          <Col lg={10}>
            {data && (
              <UpdatePatent
                itemId={isEditItem}
                parentId={id}
                innerdata={data}
              />
            )}
          </Col>
        </Row>
      ) : (
        <>
          {showForAprov == true ? (
            <ViewModal itemId={isEditItem} parentId={id} innerdata={data} />
          ) : (
            <Card>
              <Card.Body>
                <h4>Update or delete Patent</h4>
                {isResponse && (
                  <Alert variant="danger" className="my-2">
                    {isResponse}
                  </Alert>
                )}
                <div className="table-responsive">
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Title of the Patent</th>
                        <th>Principal Inventor </th>
                        <th>Co-inventors</th>
                        {/* <th>Application Number</th> */}
                        <th>Patent No./Design No.</th>
                        <th>Filing Country</th>
                        {/* <th>Subject Category</th> */}
                        <th>Filing Date</th>
                        <th>Publication Date</th>
                        <th>Patent Status</th>

                        {Role === "Chess" || Role === "Music" ? (
                          <th>Action</th>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length >= 1
                        ? data
                          ?.filter(
                            (item) =>
                              // Filter the data array based on the search query
                              item &&
                              (item.title
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase())
                                //  ||
                                // item.email
                                //   ?.toLowerCase()
                                //   .includes(searchQuery.toLowerCase()) ||
                                // item.department
                                //   ?.toLowerCase()
                                //   .includes(searchQuery.toLowerCase())
                              )
                          )
                          ?.map((item, i) => (
                            <tr key={item?._id}>


                              <td>{item.title}</td>
                              <td>{item.inventors}</td>
                              <td>{item.applicantName}</td>
                              {/* <td>{item.applicationNumber}</td> */}
                              <td>{item.patentNumber}</td>
                              <td>{item.country}</td>
                              {/* <td>{patent.subjectCategory}</td> */}
                              <td>{item.fillingDate}</td>
                              <td>{item.publicationDate}</td>
                              <td>{item.status}</td>
                              {/* <td>
                                {Role == "Chess" &&
                                  item?.superDuperAdminApprovalStatus ==
                                  "Pending" ? (
                                  <Image
                                    src={img}
                                    width={40}
                                    height={8}
                                    alt="Picture of the marquee"
                                    className="pr-1"
                                  />
                                ) : (
                                  ""
                                )}
                                {i + 1}
                              </td>{" "} */}
                              {/* You can use i+1 as the index */}

                              <td>
                                {(Role && Role === "Chess") ||
                                  Role === "Music" ? (
                                  <>

                                    <button
                                      onClick={() => deleteItem(item?._id)}
                                      className="btn btn-danger"
                                    >
                                      Delete
                                    </button>

                                    <br /> <br />

                                    <button
                                      onClick={() => editItem(item?._id)}
                                      className="btn btn-primary "
                                    >
                                      Update
                                      {/* <Edit /> */}
                                    </button>

                                    {/* <Col lg={2}>
                                        <button
                                          onClick={() => ViewData(item._id)}
                                          className="btn btn-primary"
                                        >
                                          View
                                          <Eye />
                                        </button>
                                      </Col> */}

                                  </>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </>
  );
};

// -------------------------------add org item----------------

// _______________________________________________________________

const Patents = () => {
  const { user, userLoggedIn, loading, error, _id } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      _id: state.Auth._id,
    })
  );

  let profileId = user._id ? user._id : user.user ? user.user._id : "";

  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
      ? user.user.moniterd_ttl
      : "";
  let Departmentforfilter = user.department
    ? user.department
    : user.user.department;
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;
  let profileName = user.name ? user.name : user.user.name;

  // const user = useSelector((state)=> state.user)
  const [data, setData] = useState<userData[]>([]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mob, setMob] = useState("");
  // const [position, setPosition] = useState(0);
  const [position, setPosition] = useState<string>("0");
  const [password, setPassword] = useState("");
  const [designation, setDesignation] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [role, setRole] = useState(Role === "Chess" ? "" : "User");
  const [doj, setDoj] = useState("");

  const [department, setDepartment] = useState("");

  const [title, setTitle] = useState<string>("");
  const [inventors, setInventors] = useState<string>(""); // or array if there are multiple inventors
  const [applicantName, setApplicantName] = useState<string>("");
  const [applicationNumber, setApplicationNumber] = useState<string>("");
  const [patentNumber, setPatentNumber] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [subjectCategory, setSubjectCategory] = useState<string>("");
  const [fillingDate, setFillingDate] = useState<string>(""); // Assuming date is in string format (ISO string)
  const [publicationDate, setPublicationDate] = useState<string>(""); // Same as above
  const [status, setStatus] = useState<string>("");



  // const [department, setDepartment] = useState("");
  // if (Role == 'Music') {
  //   setDepartment(Departmentforfilter)
  // }
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(); // append every thing one by one inside this form data
    formData.append("email", email);
    formData.append("name", name);
    formData.append("password", password);
    formData.append("position", position);

    formData.append("role", role);
    formData.append("mob", mob);
    formData.append("createdBy", profileName);
    formData.append("department", department);
    formData.append("designation", designation);
    formData.append("doj", doj);

    formData.append("title", title);
    formData.append("inventors", inventors);
    formData.append("applicantName", applicantName);
    formData.append("applicationNumber", applicationNumber);
    formData.append("patentNumber", patentNumber);
    formData.append("country", country);
    formData.append("subjectCategory", subjectCategory);
    formData.append("fillingDate", fillingDate);
    formData.append("publicationDate", publicationDate);
    formData.append("status", status);

    // For image (if it exists)
    if (image) {
      formData.append("image", image);
    }
    try {
      const response = await api.createData(
        `/api/addPatent/${profileId}/${Key_Key}`,
        formData
      );
      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = "https://admin.upums.ac.in/auth/login"; // Use the full URL, including the protocol (http or https)
      }
      if (response.status === 400) {
        setIsError(true);
        let errorMessage = await response.message;
        setIsResponse(errorMessage);
      }
      if (!response.status == true) {
        // console.log("response--", await response.json())
        let errorMsg = await response.json();
        setIsResponse(errorMsg.message);
        // console.log("response from update user---",response)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      if (response.status == 200 || response.status == 201) {
        alert("Patent Added Successfully");
        window.location.reload();
      }
    } catch (error: any) {
      // setIsResponse(error)
      // setShowModal(true)
      console.error("Error during add Patent:", error);
    }
  };
  // console.log(user, "USER", profileId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(
        //   `https://api.upums.ac.in/user/${profileId}/${Key_Key}`,
        //   {
        //     method: 'GET',
        //     credentials: 'include',
        //   }
        // );

        let admin =
          Role == "Chess"
            ? `/api/getPatent/${profileId}/${Key_Key}`
            : `/api/getPatent/${profileId}/${Key_Key}`;
        const response = await api.fetchDataAuth(
          // `/user/${profileId}/${Key_Key}/${Departmentforfilter}`
          `${admin}`
        );
        // console.log(response, "response");

        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = "https://admin.upums.ac.in/auth/login"; // Use the full URL, including the protocol (http or https)
        }
        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.message;
          setIsResponse(errorMessage);
        }

        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const res = response.data;
        // console.log(res, "res");
        setData(res);
      } catch (error) {
        // Handle fetch error
        console.error("Error during fetch:", error);
      }
    };

    fetchData(); // Call the async function directly

    // Note: You may want to add a cleanup function here if needed
  }, [profileId, Key_Key]); // Include dependencies if needed

  //   ------------------------------------------ setting image in the input--------------------------------
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  /*----------------  handle update----------------*/
  // console.log(data, "DATAAAAA");

  return (
    <>
      <div>
        <Card>
          <Card.Body>
            {(Role && Role === "Chess") || Role === "Music" ? (
              <Row>
                <Col lg={8}>
                  <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                    {/* {Role === "Chess" ? ( */}
                    <h3>Add Patents</h3>

                    {isError && (
                      <Alert variant="danger" className="my-2">
                        {isResponse}
                      </Alert>
                    )}
                    {showModal && <AuthModal />}
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Title of the Patent <span style={{ color: 'red' }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter  Title of the Patent"
                        value={title}
                        required
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Principal Inventor <span style={{ color: 'red' }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Inventors"
                        value={inventors}
                        required
                        onChange={(e) => setInventors(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Co-inventors (If any) <span style={{ color: 'red' }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Applicant Name"
                        value={applicantName}
                        required
                        onChange={(e) => setApplicantName(e.target.value)}
                      />
                    </Form.Group>
                    {/* 
                    <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Application Number <span style={{ color: 'red' }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Application Number"
                        value={applicationNumber}
                        required
                        onChange={(e) => setApplicationNumber(e.target.value)}
                      />
                    </Form.Group> */}

                    <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Patent No./Design No. <span style={{ color: 'red' }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Patent Number"
                        value={patentNumber}
                        required
                        onChange={(e) => setPatentNumber(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Filing Country <span style={{ color: 'red' }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Country"
                        value={country}
                        required
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </Form.Group>

                    {/* <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Subject Category <span style={{ color: 'red' }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Subject Category"
                        value={subjectCategory}
                        required
                        onChange={(e) => setSubjectCategory(e.target.value)}
                      />
                    </Form.Group> */}

                    <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Filing Date
                          {/* <span style={{ color: 'red' }}> *</span> */}
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={fillingDate}
                        // required
                        onChange={(e) => setFillingDate(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Publication Date <span style={{ color: 'red' }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={publicationDate}
                        required
                        onChange={(e) => setPublicationDate(e.target.value)}
                      />
                    </Form.Group>




                    <Form.Group
                      className="mb-3 mt-2"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setDesignation(e.target.value)
                      }
                    >
                      <Form.Label>  Patent Status</Form.Label>
                      <Form.Select defaultValue="">
                        <option value="" disabled>
                          {" "}
                          Patent Status
                        </option>
                        {/* <option value="">Select Status</option> */}
                        <option value="Published">Published</option>
                        <option value="Granted">Granted</option>

                        <option value="Pending">Pending</option>
                        <option value="Rejected">Rejected</option>
                      </Form.Select>
                    </Form.Group>



                    <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start">
                        <h5>
                          Patent Certificate <span style={{ color: "red" }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        required
                        id="image"
                        name="image"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Form.Group>




                    <Form.Group className="pt-2 pb-2">
                      <Button type="submit">Add Patent +</Button>
                      {/* <Button type='submit' className='mt-5'><Link style={{textAlign:"right", display:"bold", textDecoder:"none"}} className='text-light' to="/login">Add user</Link></Button> */}
                    </Form.Group>
                  </Form>
                </Col>

                {/* -------------------------- update data--------------------------------------------- */}
              </Row>
            ) : (
              ""
            )}

            <div
              style={{ width: "100%", height: "1px", backgroundColor: "red" }}
            ></div>

            {(Role && Role === "Chess") || Role === "Music" ? (
              <Row>
                <Col lg={12}>{user && <DeletePatent id={user.id} />}</Col>
              </Row>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3>You have no access to create a new Patent</h3>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default Patents;
